import React from 'react';
import Header from '../components/Header';

import blue from '../utils/colors';
import { rhythm } from '../utils/typography';

const NotFoundPage = () => (
  <div
    style={{
      maxWidth: rhythm(24),
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
    }}
  >
    <Header />
    <h1 style={{ color: blue }}>NOT FOUND</h1>
    <p>This page doesn&#39;t exist.</p>
  </div>
);

export default NotFoundPage;
